import axios from 'axios';

const DaftarArtikel = {

  async render() {
    return `
    <article>
    <div class="lokasi">
      <p> <a href = "#/beranda"> Beranda</a> >> <a href = "#/daftar-artikel"> Daftar Artikel</a></p>
    </div>
    <section class="daftarArtikel">
      <h2>Daftar List Artikel</h2>
      
        <div class="artikelList" id="article-list">
        <div class="article-body">
        <picture>
          <source srcset="images/image_9-small.jpg 480w, images/image_9-large.jpg 800w" sizes="(max-width: 600px) 480px, 800px" type="image/jpg">
          <img src="images/image_9.png" alt="" class = "lazyload" />
        </picture>
        <div class="textArtikel">
           <h3>Pemahaman Green Economy</h3>
          <p>Peran Ekonomi Hijau, Konsumsi dan Produksi Berkelanjutan, serta Efisiensi Sumber Daya dalam Pembangunan Berkelanjutan: Konsumsi dan Produksi Berkelanjutan bertujuan untuk meningkatkan proses produksi dan pola konsumsi agar mengurangi penggunaan sumber daya, produksi limbah, dan emisi sepanjang siklus hidup produk dan proses. Sementara itu, Efisiensi Sumber Daya merujuk pada cara penggunaan sumber daya untuk memberikan nilai kepada masyarakat dengan tujuan mengurangi jumlah sumber daya yang dibutuhkan, serta emisi dan limbah yang dihasilkan, per unit produk atau layanan. Ekonomi Hijau menawarkan pendekatan makro-ekonomi terhadap pertumbuhan ekonomi yang berkelanjutan dengan fokus utama pada investasi, penciptaan lapangan kerja, dan pengembangan keterampilan...<a href="#/detail-artikel-contoh"> 
          <br>
          <br>
          Baca Selengkapnya >> </a></p>
        </div>
        </div>
        <hr>
      </div>
   
    </section>
  </article>
    `;
  },

  async afterRender() {
    // Fungsi ini akan dipanggil setelah render()
    const articleResponse = await axios.get('http://apieco.erickowicaksono.xyz:8080/articles');
    const articles = articleResponse.data;
    const articleListElement = document.getElementById('article-list');
    articles.forEach((article) => {
      const truncatedDescription = `${article.description.substring(0, 250)}...`;
      const articleElement = `
    <div class="article-body">
      <picture>
        <source srcset="${article.url} 480w, ${article.url} 800w" sizes="(max-width: 600px) 480px, 800px" type="image/webp">
        <img src="${article.url}" alt="${article.name}" class = "lazyload" />
      </picture>
      <div class="textArtikel">
        <h3>${article.name}</h3>
        <p>${truncatedDescription}<a href="#/detail-artikel/${article.id}"> 
        <br>
        <br>
        Baca Selengkapnya >> </a></p>
      </div>
      </div>
      <hr>
  `;
      articleListElement.innerHTML += articleElement;
    });
  },
};

export default DaftarArtikel;
